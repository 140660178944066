<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Zatwierdź"
      next-button-text="Dalej"
      back-button-text="Wstecz"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Prosimy uzupełnić dane użytkownika"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Dane użytkownika
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Imię i nazwisko"
                label-for="fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="imię i nazwisko"
                  rules="required"
                >
                  <b-form-input
                    id="fullname"
                    v-model="fullname"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nr telefonu"
                label-for="cell-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nr telefonu"
                  rules="required"
                >
                  <b-form-input
                    id="cell-phone"
                    v-model="cellPhone"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <b-button
        variant="primary"
        :to="{ name: 'dashboard-main' }"
      >
        Pomiń
      </b-button>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import axios from 'axios'
import Swal from 'sweetalert2'
import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userData: [],
      officeName: '',
      selectedCountry: '',
      selectedLanguage: '',
      fullname: '',
      cellPhone: '',
      registerState: '',
      registerDistrict: '',
      registerZipCode: '',
      registerCity: '',
      registerStreet: '',
      contactState: '',
      contactDistrict: '',
      contactZipCode: '',
      contactCity: '',
      contactStreet: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      required,
      email,
      codeIcon,
    }
  },
  mounted() {
    this.getUserData()
    if (this.fullname !== '' && this.cellPhone !== '') {
      this.$router.push({ name: 'dashboard-main' })
    }
  },
  methods: {
    formSubmitted() {
      this.updateUserData()
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getUserData() {
      const token = localStorage.getItem('accessToken')
      const userData = JSON.parse(localStorage.getItem('userData'))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}User/${userData.id}`, config).then(response => {
        this.fullname = response.data.user.name
        this.cellPhone = response.data.user.tel
      }).catch(error => {
        console.log(error)
      })
    },
    updateUserData() {
      const token = localStorage.getItem('accessToken')
      const userData = JSON.parse(localStorage.getItem('userData'))
      const putData = {
        email: userData.email,
        name: this.fullname,
        tel: this.cellPhone,
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      axios.put(`${process.env.VUE_APP_API_URL}User/${userData.id}`, putData, config).then(response => {
        if (response.status === 200) {
          Swal.fire('Zaktualizowano dane', '', 'success')
        }
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
  },
}
</script>
